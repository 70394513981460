import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import HeaderWithMenu from "./components/HeaderWithMenu";
import spinnerIndicator from "./Assets/Indicator.png";
import LoadingSpinner from "./components/LoadingSpinner";
import { useLoadingSpinner } from "./Context/LoadingContext";
import Notification from "./components/Notification";
import { useLocalStorage } from "./Context/useLocalStorage";
import { useUser } from "./Context/userContext";
function AuthWrapper({ children }) {

const {User} = useUser()
  let location = useLocation();
  let { isLoading } = useLoadingSpinner();
  console.log(User.authData)
  console.log(!User.authData?"true":"false")
if (!window.location.origin.includes("localhost") && !User.authData) {
  //if (!window.location.origin.includes("localhost") && !User.authData) {
  // if (false) {
    // Si el usuario no está autenticado, redirige a la página de inicio de sesión
    return <Navigate to="/login" replace />;
  }


  
  // Si el usuario está autenticado, renderiza los componentes hijos
  return (
    <>
      <HeaderWithMenu
        title={mapRouteToTitle(location.pathname)}
      ></HeaderWithMenu>
      {isLoading && <LoadingSpinner></LoadingSpinner>}
      {/* <NotificationToast></NotificationToast> */}
      {children}
    </>
  );
}

const NotificationToast =()=>{

  return(
    
    <div style={{position:"absolute",top:40,height:300, width:"95%",zIndex:999}}>
  <Notification
  onclose={()=>{console.log(false)}}
            className="notification"
            show={true}
            title={"Esta conta não tem permissão para aceder à Compra Ágil"}
            body={"Para solicitar o acesso, por favor faça um pedido da categoria “Acesso ou privilēgios” a partir do Digital Service Desk."}
          ></Notification>
    </div>
  )
}

export default AuthWrapper;

const mapRouteToTitle = (path) => {
  let map = {
    "/licence-plate": "Nova Venda",
    "/menu": "Menu",
    "/cart": "Compra Ágil",
    "/history": "Histórico",
    "/finish": "Nova Venda",
    "/pending-cart": "Compra Pendente",
    "/history-products": "Histórico",
  };
  return map[path] || "Histórico";
};
